<script setup lang="ts"></script>
<template>
	<svg
		width="328"
		height="212"
		viewBox="0 0 328 212"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		class="absolute"
	>
		<mask
			id="mask0_61_40120"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="-5"
			y="-124"
			width="337"
			height="336"
		>
			<rect
				width="336"
				height="336"
				transform="translate(-4.5 -124)"
				fill="url(#paint0_radial_61_40120)"
			/>
		</mask>
		<g mask="url(#mask0_61_40120)">
			<circle cx="163.5" cy="44" r="47.5" stroke="#E5E5E5" />
			<circle cx="163.5" cy="44" r="47.5" stroke="#E5E5E5" />
			<circle cx="163.5" cy="44" r="71.5" stroke="#E5E5E5" />
			<circle cx="163.5" cy="44" r="95.5" stroke="#E5E5E5" />
			<circle cx="163.5" cy="44" r="119.5" stroke="#E5E5E5" />
			<circle cx="163.5" cy="44" r="143.5" stroke="#E5E5E5" />
			<circle cx="163.5" cy="44" r="167.5" stroke="#E5E5E5" />
		</g>
		<defs>
			<radialGradient
				id="paint0_radial_61_40120"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(168 168) rotate(90) scale(168 168)"
			>
				<stop />
				<stop offset="1" stop-opacity="0" />
			</radialGradient>
		</defs>
	</svg>
</template>
